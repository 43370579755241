import axios from "axios"
import { navigate } from "gatsby";
import moment from "moment"

const baseUrl = process.env.GATSBY_APIOMAT_MODULE_OAUTH_BASEURL;
const clientSecret = process.env.GATSBY_APIOMAT_MODULE_WEBSITEOFFER_KEY;
const system = process.env.GATSBY_APIOMAT_MODULE_WEBSITEOFFER_SYSTEMID;

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
  return isBrowser() && window.sessionStorage.getItem("gatsbyUser")
    ? JSON.parse(window.sessionStorage.getItem("gatsbyUser"))
    : {}
}

const setUser = user => {
  return isBrowser() ? window.sessionStorage.setItem("gatsbyUser", JSON.stringify(user)) : null;
}

export const handleLogin = (username, password) => {

  const params = new URLSearchParams()
  params.append("grant_type", "aom_user")
  params.append('client_id', "WebsiteAngebot",)
  params.append('client_secret', clientSecret)
  params.append('scope',"read write")
  params.append('username', username)
  params.append('app', "WebsiteAngebot")
  params.append('password', password)
  params.append('system', system)

  const config =  {
    headers: {
      "X-apiomat-system": process.env.GATSBY_APIOMAT_MODULE_WEBSITEOFFER_SYSTEMID,
      "X-apiomat-apikey": process.env.GATSBY_APIOMAT_MODULE_WEBSITEOFFER_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
      "X-apiomat-fullupdate": "true",
    },
  }

  return axios
    .post(baseUrl, params, config)
    .then(response => {
      const res = response.data;
      setUser({
        access_token: res.access_token,
        token_type: res.token_type,
        refresh_token: res.refresh_token,
        expires_in: res.expires_in,
        scope: res.scope,
        aom_model: res.aom_model,
        aom_module: res.aom_module,
        aom_extra: res.aom_extra,
        starting_date: new Date(),
      })
      return response;
    })
    .catch(e => {
      console.warn("Wrong login information")
      throw(e);
    })
}

export const refreshToken = () => {
  const user = getUser()
  const params = new URLSearchParams()
  params.append("grant_type", "refresh_token")
  params.append('client_id', "WebsiteAngebot",)
  params.append('client_secret', clientSecret)
  params.append('scope',"read write")
  params.append('app', "WebsiteAngebot")
  params.append('system', system)
  params.append('refresh_token', user.refresh_token)


  const config =  {
    headers: {
      "X-apiomat-system": process.env.GATSBY_APIOMAT_MODULE_WEBSITEOFFER_SYSTEMID,
      "X-apiomat-apikey": process.env.GATSBY_APIOMAT_MODULE_WEBSITEOFFER_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
      "X-apiomat-fullupdate": "true",
    },
  }

  return axios
  .post(baseUrl, params, config)
  .then(response => {
    const res = response.data;
    setUser({
      access_token: res.access_token,
      token_type: res.token_type,
      refresh_token: res.refresh_token,
      expires_in: res.expires_in,
      scope: res.scope,
      aom_model: res.aom_model,
      aom_module: res.aom_module,
      aom_extra: res.aom_extra,
      starting_date: new Date(),
    })
    return response;
  })
  .catch(e => {
    console.warn("Wrong login information")
    throw(e);
  })
}

export const authenticateToken = () => {
  return true;
}

export const isLoggedIn = () => {
  const user = getUser();
  if (user.refresh_token) {
    if (moment(new Date).diff(moment(user.starting_date).add(user.expires_in, "second"), "hour") > -8) {
      refreshToken().then().catch(e => {
        navigate("/login")
      });
    }
  }
  return !!user.access_token
}

export const logout = callback => {
  setUser({})
  callback && callback();
}
